import {getDocumentHtmlSafeNonces, verifyResponseHtmlSafeNonce} from '@github-ui/html-safe-nonce'
import {ghTrustedTypes} from '@github-ui/trusted-types'

// This is a no-op policy to be used with server-side rendered HTML.
export const serverXSafeHTMLPolicy = ghTrustedTypes.createPolicy('server-x-safe-html', {
  createHTML: (s: string, response: Response) => {
    verifyResponseHtmlSafeNonce(getDocumentHtmlSafeNonces(document), response)
    return s
  },
})
